import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { SmallBlueArrow } from '../components/layout/arrows';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Title from '../components/layout/title';

const MissionPage = ({ location, data }) => {
  const Advocacy = getImage(data.Advocacy.childImageSharp);
  const DoloresView = getImage(data.DoloresView.childImageSharp);
  const GoldenGateAtNight = getImage(data.GoldenGateAtNight.childImageSharp);
  return (
    <Layout>
      <SEO
        title="GrowSF: Our Mission"
        description="These are the issues we care about and will fight for. We support policies that make sense for San Francisco's future, not its past."
        pathname={location.pathname}
      />
      <div className="max-w-7xl mx-auto px-5 sm:px-6 lg:px-8">
        <Title title="Our Mission">
          GrowSF is a non-partisan organization that wants San Francisco to be safe, clean,
          affordable, and vibrant.
        </Title>
        <GatsbyImage className="m-auto" image={DoloresView} alt="Dolores View" />
        <div className="sm:w-2/3 m-auto my-6 sm:my-16">
          <p className="text-xl text-gray-700 leading-relaxed my-8 mb-6">
            <b className="text-gray-800">GrowSF prioritizes outcomes over ideologies.</b> Our local
            leaders are not focused on actual progress, and residents feel under-informed about
            local issues and elections. We want to change that.
          </p>
          <p className="text-xl text-gray-700 leading-relaxed my-8 mb-6">
            <b className="text-gray-800">Our elected officials have failed us on the basics:</b>{' '}
            housing, transit, schools, and public safety. We want common-sense solutions to the
            problems that regular people face. It’s time to fix San Francisco.
          </p>
          <p className="text-xl text-gray-700 leading-relaxed my-8 mb-6">
            <b className="text-gray-800">
              GrowSF supports solutions that embrace San Francisco values.
            </b>{' '}
            We celebrate art and culture, value compassion, seek sustainability, and practice social
            justice. GrowSF supports a city government that is efficiently managed, responsive to
            quality of life issues, and above all follows common sense.
          </p>
        </div>
        <div className="bg-gradient-to-tr from-brand-blue-5 via-brand-blue-5 to-brand-green-4 p-6 sm:p-12">
          <div className="text-center pb-6 sm:pb-8 border-b border-slate-100/25">
            <h3 className="text-2xl sm:text-3xl font-black text-slate-100">Our Goals</h3>
            <p className="text-lg sm:text-xl text-slate-300">
              We have simple, but effective, goals
            </p>
          </div>
          <div className="grid md:grid-cols-12 sm:gap-12 lg:gap-12 py-8">
            <div className="col-span-3 mb-6 sm:mb-0">
              <h3 className="text-xl font-bold text-slate-100 mb-4">Educate on local issues</h3>
              <p className="leading-normal text-slate-300">
                By being better informed, we can be better citizens of our incredible city and work
                together to drive positive change.
              </p>
            </div>
            <div className="col-span-3 mb-6 sm:mb-0">
              <h3 className="text-xl font-bold text-slate-100 mb-4">
                Advocate for change through voting
              </h3>
              <p className="leading-normal text-slate-300">
                Voting is the ultimate civic act, and it matters! Many major elections in SF have
                been decided by fewer than 500 votes.{' '}
                <Link className="text-brand-green-3 hover:underline" to="/voter-guide">
                  Click to see our latest voter guide!
                </Link>
              </p>
            </div>
            <div className="col-span-3 mb-6 sm:mb-0">
              <h3 className="text-xl font-bold text-slate-100 mb-4">Share ways to get involved</h3>
              <p className="leading-normal text-slate-300">
                Getting involved can be signing a petition, Zooming into a public meeting, emailing,
                tweeting, and more. It's simple.
              </p>
            </div>
            <div className="col-span-3 mb-6 sm:mb-0">
              <h3 className="text-xl font-bold text-slate-100 mb-4">
                Celebrate the beauty of San Francisco
              </h3>
              <p className="leading-normal text-slate-300">
                San Francisco is wonderful and we should seek to invest in it and make it even
                better. SF isn’t a museum — it's an ever-changing city full of amazing people!
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Our Strategy */}
      <div className="max-w-7xl mx-auto px-5 sm:px-6 lg:px-8 mt-12 sm:mt-24">
        <h1 className="text-3xl md:text-5xl font-black text-gray-900 tracking-tight mt-8">
          Our Strategy
        </h1>
        <div className="grid sm:grid-cols-12 sm:gap-12">
          <div className="col-span-12 sm:col-span-7">
            <p className="text-xl text-gray-700 leading-relaxed my-8">
              Our analysis has shown that most San Francisco voters are unhappy with the policies
              our elected officials are implementing, yet they keep re-electing those same
              politicians. This happens because voters lack robust and reliable research on what
              politicians actually believe, and a single-party city lacks the normal signals that
              voters use to make up their minds.
            </p>
            <p className="text-xl text-gray-700 leading-relaxed my-8">
              GrowSF has a simple strategy: build a trusted and reliable brand by publishing
              unbiased and well-researched{' '}
              <Link className="underline text-brand-blue-4 underlineline" to="/voter-guide/">
                San Francisco voter guides
              </Link>
              , running political education events, supporting good reforms (like{' '}
              <Link className="text-brand-blue-4 underline" to="/make-shared-spaces-permanent">
                making outdoor dining permanent
              </Link>
              ), and keeping voters regularly informed via the{' '}
              <OutboundLink
                className="text-brand-blue-4 underline"
                href="https://report.growsf.org/"
                target="_blank"
              >
                GrowSF weekly newsletter
              </OutboundLink>
              .
            </p>
            <h3 className="text-brand-blue-4 font-bold text-2xl">
              Just voting for a Democrat means nothing when every candidate is a Democrat and they
              all believe different things.
            </h3>
            <p className="text-xl text-gray-700 leading-relaxed my-8">
              The most important resource for voters to have is a clear, well-written, and concise
              explanation of the candidates and ballot propositions in every election. GrowSF is
              that resource.
            </p>
            <p className="text-xl text-gray-700 leading-relaxed my-8">
              GrowSF is not controlled by any political interest groups or political parties. We
              are, and will always be, independent. We are committed to giving you the best advice
              available and explaining the trade-offs of all of your choices.
            </p>
          </div>
          <div className="col-span-12 sm:col-span-5">
            <figure>
              <GatsbyImage
                image={Advocacy}
                alt="GrowSF advocates working with small business owner Brian Coyle to save outdoor dining."
                className="w-full"
              />
              <figcaption className="text-sm text-slate-500 my-8">
                GrowSF volunteers saving outdoor dining with small business owner Brian Coyle.
              </figcaption>
            </figure>
          </div>
        </div>
        <GatsbyImage className="m-auto" image={GoldenGateAtNight} alt="Golden Gate at Night" />
      </div>
      {/* End Strategy */}

      {/* Long Vision */}
      <div className="max-w-7xl mx-auto px-5 sm:px-6 lg:px-8 my-6 sm:my-12">
        <div className="grid sm:grid-cols-12 sm:gap-12 items-center">
          <div className="col-span-12 sm:col-span-4">
            <h1 className="text-3xl md:text-5xl font-black text-gray-900 tracking-tight my-6">
              Our Long Vision
            </h1>
            <p className="text-brand-blue-4 font-bold text-2xl">
              We are setting off on a ten-year journey to fix San Francisco.{' '}
              <span className="font-bold">
                Help us get there by{' '}
                <a href="/donate" className="underline">
                  donating
                </a>{' '}
                today.
              </span>
            </p>
          </div>
          <div className="col-span-12 sm:col-span-8">
            <p className="text-xl text-gray-700 leading-relaxed my-8">
              We will not fix San Francisco one project at a time. We must fight for structural
              change which includes electing better officials and passing legislation.
            </p>
            <p className="text-xl text-gray-700 leading-relaxed my-8">
              We will run ballot measures to reform policies that can't be changed any other way,
              and especially to reform the City Charter in order to make San Francisco's rules less
              discretionary and corruptible.
            </p>
            <p className="text-xl text-gray-700 leading-relaxed my-8">
              We aren't looking to start our own political party — but you'll know that anyone we
              endorse believes in a safer, cleaner, more affordable, and economically vibrant city.
            </p>
          </div>
        </div>
      </div>
      {/* End Long Vision */}
    </Layout>
  );
};

export default MissionPage;

export const query = graphql`
  query {
    Advocacy: file(relativePath: { eq: "issues/danny-coyles.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    GoldenGateAtNight: file(relativePath: { eq: "mission/gordon-mak-vxRAoTXT-YI-unsplash.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    DoloresView: file(
      relativePath: { eq: "mission/koushik-chowdavarapu-vfDfO2UkqAI-unsplash.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
